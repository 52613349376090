<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      adminlist: [],
      adminsearch: "",
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.admin.text");
    this.items = [
      {
        text: this.$t("menuitems.admin.text"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo.topuid >= 1) {
      this.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getadminlist();
  },
  methods: {
    getadminlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getadminuserlist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.adminlist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteadmin(id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deleteadmintip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.loading = true;
          that.$axios
            .post(
              that.apiuri,
              {
                action: "removeadminuser",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.loading = false;
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getadminlist();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
  },
  computed: {
    searchinadminlist() {
      var datas = this.adminlist;
      if (this.adminsearch) {
        datas = datas.filter((value) => {
          return (
            !this.adminsearch ||
            value.username
              .toLowerCase()
              .includes(this.adminsearch.trim().toLowerCase())
          );
        });
      }
      return datas;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!block">
            <div class="row col-md-12">
              <p>
                <router-link :to="{ path: '/admin/add' }">
                  <a href="javascaript:;" class="btn btn-success">
                    <i class="ri-file-add-line"></i>
                    {{ $t("admin.add.text") }}
                  </a>
                </router-link>
              </p>
            </div>
            <div class="row col-md-12">
              <input
                type="search"
                v-model="adminsearch"
                class="form-control"
                :placeholder="$t('admin.lists.search')"
              />
            </div>
            <div class="row col-md-12">
              <table id="deviceslist_table" class="table table-striped">
                <thead>
                  <tr>
                    <th class="sortStyle">{{ $t("admin.lists.username") }}</th>
                    <th class="sortStyle">{{ $t("admin.lists.phone") }}</th>
                    <th class="sortStyle">{{ $t("admin.lists.status") }}</th>
                    <th class="sortStyle">{{ $t("device.lists.action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!searchinadminlist || !adminlist">
                    <td colspan="7" align="center">
                      <el-empty
                        :description="$t('device.error.nodata')"
                      ></el-empty>
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="val in searchinadminlist"
                    :class="'admin' + val.id"
                    :key="val.id"
                  >
                    <td>{{ val.username }}</td>
                    <td>{{ val.phone }}</td>
                    <td>
                      <span v-if="val.status == 0" class="badge badge-success">
                        {{ $t("admin.lists.normal") }}
                      </span>
                      <span
                        v-else-if="val.status == 1"
                        class="badge badge-danger"
                      >
                        {{ $t("admin.lists.limit") }}
                      </span>
                      <span
                        v-else-if="val.status == 2"
                        class="badge badge-danger"
                      >
                        {{ $t("admin.lists.disable") }}
                      </span>
                    </td>
                    <td>
                      <router-link
                        :to="{ path: '/admin/add', query: { id: val.id } }"
                      >
                        <a class="btn btn-info rounded-pill">
                          {{ $t("admin.lists.edit") }}
                        </a>
                      </router-link>

                      <button
                        type="button"
                        class="btn btn-danger rounded-pill"
                        @click="deleteadmin(val.id)"
                      >
                        {{ $t("device.lists.delete") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-body" v-else>
            <el-result
              icon="error"
              :title="$t('global.error')"
              :subTitle="$t('global.text.nopermission')"
            >
              <template slot="extra">
                <el-button type="primary" size="medium">返回</el-button>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>